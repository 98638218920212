import axios from "axios"
import { post } from "api/base"

export default (token) => {
  const authApiClient = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT_URL,
  })

  authApiClient.interceptors.request.use((req) => {
    req.headers.authorization = `Bearer ${token}`
    return req
  })

  authApiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      return Promise.reject(error.response)
    }
  )

  return authApiClient
}

export const siginIn = async (email, password) => {
  return await post("/admin/login", { email, password })
}
