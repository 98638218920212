import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import LinkRouter from "./LinkRouter"
import { useLibrary } from "hooks/Library"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    height: 80,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.only("xs")]: {
      height: 75,
    },
  },
  logo: {
    objectFit: "cover",
    height: "auto",
    maxWidth: 300,
    [theme.breakpoints.only("xs")]: {
      maxWidth: 265,
    },
  },
  bar: {
    height: 36,
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.only("xs")]: {
      height: 12,
    },
  },
  title: {
    color: "white",
    [theme.breakpoints.only("xs")]: {
      // fontSize: 18,
      // lineHeight: 2,
      // margin: "0 auto"
      display: "none",
    },
  },
}))

const Header = (props) => {
  const classes = useStyles()
  const library = useLibrary()

  return (
    <div className={classes.root}>
      <div className={classes.bar}></div>
      <div className={classes.container}>
        <LinkRouter to="/">
          <img className={classes.logo} src="/logo.png" alt="lib-logo" />
        </LinkRouter>
        <div className={classes.title}>
          <Typography variant="h5" component="h1">
            Welcome {library.name}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default Header
